/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByUser = /* GraphQL */ `
  query CustomerByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByUniversity = /* GraphQL */ `
  query CustomerByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByStudentId = /* GraphQL */ `
  query CustomerByStudentId(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByStudentID(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeliverer = /* GraphQL */ `
  query GetDeliverer($id: ID!) {
    getDeliverer(id: $id) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listDeliverers = /* GraphQL */ `
  query ListDeliverers(
    $filter: ModelDelivererFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        orders {
          items {
            id
            owner
            customerID
            venueID
            eventID
            delivererID
            price
            totalPrice
            status
            notes
            createdAt
            timeSlot
            timeSlotID
            paymentMethod
            chargeID
            serviceOption
            locationName
            _version
            _deleted
            _lastChangedAt
            updatedAt
            customer {
              id
              userID
              universityID
              studentID
              cartID
              cbordPaymentToken
              firstName
              lastName
              phoneNumber
              image_url
              readGroups
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            history {
              nextToken
              startedAt
            }
            orderItems {
              nextToken
              startedAt
            }
            venue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const delivererByUser = /* GraphQL */ `
  query DelivererByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDelivererFilterInput
    $limit: Int
    $nextToken: String
  ) {
    delivererByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        orders {
          items {
            id
            owner
            customerID
            venueID
            eventID
            delivererID
            price
            totalPrice
            status
            notes
            createdAt
            timeSlot
            timeSlotID
            paymentMethod
            chargeID
            serviceOption
            locationName
            _version
            _deleted
            _lastChangedAt
            updatedAt
            customer {
              id
              userID
              universityID
              studentID
              cartID
              cbordPaymentToken
              firstName
              lastName
              phoneNumber
              image_url
              readGroups
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            history {
              nextToken
              startedAt
            }
            orderItems {
              nextToken
              startedAt
            }
            venue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const deliverersByUniversity = /* GraphQL */ `
  query DeliverersByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDelivererFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliverersByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        orders {
          items {
            id
            owner
            customerID
            venueID
            eventID
            delivererID
            price
            totalPrice
            status
            notes
            createdAt
            timeSlot
            timeSlotID
            paymentMethod
            chargeID
            serviceOption
            locationName
            _version
            _deleted
            _lastChangedAt
            updatedAt
            customer {
              id
              userID
              universityID
              studentID
              cartID
              cbordPaymentToken
              firstName
              lastName
              phoneNumber
              image_url
              readGroups
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            history {
              nextToken
              startedAt
            }
            orderItems {
              nextToken
              startedAt
            }
            venue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeliverers = /* GraphQL */ `
  query SyncDeliverers(
    $filter: ModelDelivererFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliverers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        orders {
          items {
            id
            owner
            customerID
            venueID
            eventID
            delivererID
            price
            totalPrice
            status
            notes
            createdAt
            timeSlot
            timeSlotID
            paymentMethod
            chargeID
            serviceOption
            locationName
            _version
            _deleted
            _lastChangedAt
            updatedAt
            customer {
              id
              userID
              universityID
              studentID
              cartID
              cbordPaymentToken
              firstName
              lastName
              phoneNumber
              image_url
              readGroups
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            history {
              nextToken
              startedAt
            }
            orderItems {
              nextToken
              startedAt
            }
            venue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getDirector = /* GraphQL */ `
  query GetDirector($id: ID!) {
    getDirector(id: $id) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listDirectors = /* GraphQL */ `
  query ListDirectors(
    $filter: ModelDirectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const directorsByUser = /* GraphQL */ `
  query DirectorsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDirectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directorsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const directorsByUniversity = /* GraphQL */ `
  query DirectorsByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDirectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directorsByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDirectors = /* GraphQL */ `
  query SyncDirectors(
    $filter: ModelDirectorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDirectors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const providersByUser = /* GraphQL */ `
  query ProvidersByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providersByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const providersByUniversity = /* GraphQL */ `
  query ProvidersByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providersByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProviders = /* GraphQL */ `
  query SyncProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const vendorsByUser = /* GraphQL */ `
  query VendorsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const vendorsByVenue = /* GraphQL */ `
  query VendorsByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const vendorsByUniversity = /* GraphQL */ `
  query VendorsByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVendors = /* GraphQL */ `
  query SyncVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVendors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listStaffs = /* GraphQL */ `
  query ListStaffs(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffByUser = /* GraphQL */ `
  query StaffByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffByVenue = /* GraphQL */ `
  query StaffByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffByUniversity = /* GraphQL */ `
  query StaffByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStaff = /* GraphQL */ `
  query SyncStaff(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStaff(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        venueID
        universityID
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        parentUniversity {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        parentVenue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarts = /* GraphQL */ `
  query SyncCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        status
        createdAt
        timeSlot
        timeSlotID
        serviceOption
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const reservationsByVenue = /* GraphQL */ `
  query ReservationsByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservationsByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        status
        createdAt
        timeSlot
        timeSlotID
        serviceOption
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const reservationsByCustomer = /* GraphQL */ `
  query ReservationsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservationsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        status
        createdAt
        timeSlot
        timeSlotID
        serviceOption
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const reservationsByEvent = /* GraphQL */ `
  query ReservationsByEvent(
    $eventID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservationsByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        status
        createdAt
        timeSlot
        timeSlotID
        serviceOption
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReservations = /* GraphQL */ `
  query SyncReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        status
        createdAt
        timeSlot
        timeSlotID
        serviceOption
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderState = /* GraphQL */ `
  query GetOrderState($id: ID!) {
    getOrderState(id: $id) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listOrderStates = /* GraphQL */ `
  query ListOrderStates(
    $filter: ModelOrderStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        orderID
        status
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const statesByOrder = /* GraphQL */ `
  query StatesByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statesByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        orderID
        status
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderStates = /* GraphQL */ `
  query SyncOrderStates(
    $filter: ModelOrderStateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        orderID
        status
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const ordersByVenue = /* GraphQL */ `
  query OrdersByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const ordersByCustomer = /* GraphQL */ `
  query OrdersByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const ordersByDeliverer = /* GraphQL */ `
  query OrdersByDeliverer(
    $delivererID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDeliverer(
      delivererID: $delivererID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const ordersByEvent = /* GraphQL */ `
  query OrdersByEvent(
    $eventID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        customerID
        venueID
        eventID
        delivererID
        price
        totalPrice
        status
        notes
        createdAt
        timeSlot
        timeSlotID
        paymentMethod
        chargeID
        serviceOption
        locationName
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        history {
          items {
            id
            owner
            orderID
            status
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        orderItems {
          items {
            id
            owner
            orderID
            foodID
            price
            amount
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venue {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        orderID
        foodID
        price
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const orderItemsByOrder = /* GraphQL */ `
  query OrderItemsByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        orderID
        foodID
        price
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderItems = /* GraphQL */ `
  query SyncOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        orderID
        foodID
        price
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUniversity = /* GraphQL */ `
  query GetUniversity($id: ID!) {
    getUniversity(id: $id) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listUniversitys = /* GraphQL */ `
  query ListUniversitys(
    $filter: ModelUniversityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUniversitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUniversities = /* GraphQL */ `
  query SyncUniversities(
    $filter: ModelUniversityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUniversities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listBusinesss = /* GraphQL */ `
  query ListBusinesss(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const businessesByUniversity = /* GraphQL */ `
  query BusinessesByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessesByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const businessesByProvider = /* GraphQL */ `
  query BusinessesByProvider(
    $providerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessesByProvider(
      providerID: $providerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBusinesses = /* GraphQL */ `
  query SyncBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const venuesByUniversity = /* GraphQL */ `
  query VenuesByUniversity(
    $universityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    venuesByUniversity(
      universityID: $universityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVenues = /* GraphQL */ `
  query SyncVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVenues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const eventsByVenue = /* GraphQL */ `
  query EventsByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventException = /* GraphQL */ `
  query GetEventException($id: ID!) {
    getEventException(id: $id) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listEventExceptions = /* GraphQL */ `
  query ListEventExceptions(
    $filter: ModelEventExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventExceptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        eventID
        name
        description
        type
        date
        startTime
        endTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exceptionsByEvent = /* GraphQL */ `
  query ExceptionsByEvent(
    $eventID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exceptionsByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        eventID
        name
        description
        type
        date
        startTime
        endTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventExceptions = /* GraphQL */ `
  query SyncEventExceptions(
    $filter: ModelEventExceptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        eventID
        name
        description
        type
        date
        startTime
        endTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getEventMenu = /* GraphQL */ `
  query GetEventMenu($id: ID!) {
    getEventMenu(id: $id) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listEventMenus = /* GraphQL */ `
  query ListEventMenus(
    $filter: ModelEventMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        eventID
        menuID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        event {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const eventMenuLinksByEvent = /* GraphQL */ `
  query EventMenuLinksByEvent(
    $eventID: ID
    $menuID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventMenuLinksByEvent(
      eventID: $eventID
      menuID: $menuID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        eventID
        menuID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        event {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const eventMenuLinksByMenu = /* GraphQL */ `
  query EventMenuLinksByMenu(
    $menuID: ID
    $eventID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventMenuLinksByMenu(
      menuID: $menuID
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        eventID
        menuID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        event {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEventMenus = /* GraphQL */ `
  query SyncEventMenus(
    $filter: ModelEventMenuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventMenus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        eventID
        menuID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        event {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const menusByVenue = /* GraphQL */ `
  query MenusByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menusByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMenus = /* GraphQL */ `
  query SyncMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getMenuFood = /* GraphQL */ `
  query GetMenuFood($id: ID!) {
    getMenuFood(id: $id) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listMenuFoods = /* GraphQL */ `
  query ListMenuFoods(
    $filter: ModelMenuFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuFoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        menuID
        foodID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const menuFoodLinksByMenu = /* GraphQL */ `
  query MenuFoodLinksByMenu(
    $menuID: ID
    $foodID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuFoodLinksByMenu(
      menuID: $menuID
      foodID: $foodID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        menuID
        foodID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const menuFoodLinksByFood = /* GraphQL */ `
  query MenuFoodLinksByFood(
    $foodID: ID
    $menuID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuFoodLinksByFood(
      foodID: $foodID
      menuID: $menuID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        menuID
        foodID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMenuFoods = /* GraphQL */ `
  query SyncMenuFoods(
    $filter: ModelMenuFoodFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuFoods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        menuID
        foodID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menu {
          id
          owner
          venueID
          name
          description
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          events {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          foods {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        food {
          id
          owner
          venueID
          name
          description
          price
          active
          calories
          foodType
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              menuID
              foodID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getFood = /* GraphQL */ `
  query GetFood($id: ID!) {
    getFood(id: $id) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listFoods = /* GraphQL */ `
  query ListFoods(
    $filter: ModelFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const foodsByVenue = /* GraphQL */ `
  query FoodsByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    foodsByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFoods = /* GraphQL */ `
  query SyncFoods(
    $filter: ModelFoodFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFoods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTimeSlot = /* GraphQL */ `
  query GetTimeSlot($id: ID!) {
    getTimeSlot(id: $id) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTimeSlots = /* GraphQL */ `
  query ListTimeSlots(
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        venueID
        eventID
        type
        capacity
        position
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const timeSlotsByEvent = /* GraphQL */ `
  query TimeSlotsByEvent(
    $eventID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timeSlotsByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        eventID
        type
        capacity
        position
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const timeSlotsByVenue = /* GraphQL */ `
  query TimeSlotsByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timeSlotsByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        eventID
        type
        capacity
        position
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const orderedTimeSlotsByVenue = /* GraphQL */ `
  query OrderedTimeSlotsByVenue(
    $venueID: ID
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderedTimeSlotsByVenue(
      venueID: $venueID
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        eventID
        type
        capacity
        position
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTimeSlots = /* GraphQL */ `
  query SyncTimeSlots(
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimeSlots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        venueID
        eventID
        type
        capacity
        position
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        venueID
        reportUri
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reporstByVenue = /* GraphQL */ `
  query ReporstByVenue(
    $venueID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reporstByVenue(
      venueID: $venueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        venueID
        reportUri
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        venueID
        reportUri
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAvailability = /* GraphQL */ `
  query GetAvailability($id: ID!) {
    getAvailability(id: $id) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAvailabilitys = /* GraphQL */ `
  query ListAvailabilitys(
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        businessID
        name
        description
        active
        recurrentDays
        startDate
        endDate
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            availabilityID
            name
            description
            type
            date
            startTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const availabilitiesByBusiness = /* GraphQL */ `
  query AvailabilitiesByBusiness(
    $businessID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availabilitiesByBusiness(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        businessID
        name
        description
        active
        recurrentDays
        startDate
        endDate
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            availabilityID
            name
            description
            type
            date
            startTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAvailabilities = /* GraphQL */ `
  query SyncAvailabilities(
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        businessID
        name
        description
        active
        recurrentDays
        startDate
        endDate
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            availabilityID
            name
            description
            type
            date
            startTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAvailabilityException = /* GraphQL */ `
  query GetAvailabilityException($id: ID!) {
    getAvailabilityException(id: $id) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAvailabilityExceptions = /* GraphQL */ `
  query ListAvailabilityExceptions(
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilityExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        availabilityID
        name
        description
        type
        date
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const exceptionsByAvailability = /* GraphQL */ `
  query ExceptionsByAvailability(
    $availabilityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exceptionsByAvailability(
      availabilityID: $availabilityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        availabilityID
        name
        description
        type
        date
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAvailabilityExceptions = /* GraphQL */ `
  query SyncAvailabilityExceptions(
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAvailabilityExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        availabilityID
        name
        description
        type
        date
        startTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const servicesByBusiness = /* GraphQL */ `
  query ServicesByBusiness(
    $businessID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    servicesByBusiness(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        title
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        businessID
        customerID
        serviceID
        price
        status
        createdAt
        chargeID
        note
        locationName
        date
        time
        duration
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        service {
          id
          owner
          businessID
          name
          description
          duration
          price
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          business {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingsByBusiness = /* GraphQL */ `
  query BookingsByBusiness(
    $businessID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByBusiness(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        businessID
        customerID
        serviceID
        price
        status
        createdAt
        chargeID
        note
        locationName
        date
        time
        duration
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        service {
          id
          owner
          businessID
          name
          description
          duration
          price
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          business {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingsByCustomer = /* GraphQL */ `
  query BookingsByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        businessID
        customerID
        serviceID
        price
        status
        createdAt
        chargeID
        note
        locationName
        date
        time
        duration
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        service {
          id
          owner
          businessID
          name
          description
          duration
          price
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          business {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingsByService = /* GraphQL */ `
  query BookingsByService(
    $serviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByService(
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        businessID
        customerID
        serviceID
        price
        status
        createdAt
        chargeID
        note
        locationName
        date
        time
        duration
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        service {
          id
          owner
          businessID
          name
          description
          duration
          price
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          business {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        businessID
        customerID
        serviceID
        price
        status
        createdAt
        chargeID
        note
        locationName
        date
        time
        duration
        _version
        _deleted
        _lastChangedAt
        updatedAt
        customer {
          id
          userID
          universityID
          studentID
          cartID
          cbordPaymentToken
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          readGroups
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          cart {
            id
            owner
            content
            updatedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
          }
        }
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        service {
          id
          owner
          businessID
          name
          description
          duration
          price
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          business {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
